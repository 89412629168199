.p-project-card
  padding 10px
  background-color var(--pcolor-gray-lightest)
  color var(--pcolor-card-text)
  border-radius 20px
  p
    color var(--pcolor-card-text)

.p-project-card-header
  display flex
  align-items center
  justify-content space-between
  margin-bottom 20px

.p-project-card-header-main
.p-project-card-header-helper
  display flex
  align-items center
  gap 10px

.p-project-card-heading-details
  display flex
  justify-content right
  gap 15px

.p-project-card-header-archived
  display flex
  align-items center
  font-size var(--pfont-size-xxs)
  font-weight 700
  .p-icon-archive
    position relative
    top -1px
    right -4px
    width 32px
    height 32px
    [data-pstroke]
      stroke var(--pcolor-card-text)
      stroke-width 1.5px

.p-project-card-image-wrapper
  width 40px
  height 40px

.p-project-card-heading
  font-size var(--pfont-size-md)

.p-project-card-footer
  margin-top 20px

.p-project-card-year
  margin-right 8px
  padding 4px 14px
  font-size var(--pfont-size-xxs)
  font-weight 700
  border 1px solid var(--pcolor-card-text)
  border-radius 6px
